import React from "react"
import Navigation from "../components/navigation"
import NavigationMobile from "../components/navigation-mobile"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  return (

    <div className="container" style={{}}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Ein Schlagermusik-Theater"></meta>
        <title>Röfes Plattesammlig</title>
      </Helmet>
      <Navigation></Navigation>
      <NavigationMobile></NavigationMobile>
      {children}
      <Footer title="Footer" />
    </div>
  )
}