import React from "react"
import Img from "gatsby-image"
import * as largeImageStyles from "./large_image.module.css"

export default function LargeImage(props) {
  console.log(props);
  return <div className={largeImageStyles.imagecontainer}>
            <a href={props.image.src}>
              <Img fluid={props.image} />
             </a>
          <span className={largeImageStyles.caption}>{props.caption}</span>
        </div>
}
