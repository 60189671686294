import React from "react"
import * as textBlockStyles from "./text_block.module.css"

export default function TextBlock(props) {
  return   <div className={textBlockStyles.textblock}>
  <h3>{props.title}</h3>
  <p>{props.text}</p>
  </div>
}



