import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import * as vinylImageStyles from "./vinyl.module.css"


export default function Vinyl(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        imageVinyl: file(relativePath: { eq: "vinyl_red.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        },  
      }
      `}
      render={data => (
          <div className={[vinylImageStyles.vinyl, vinylImageStyles[props.pos], vinylImageStyles[props.size]].join(' ')}>
            <Img fluid={data.imageVinyl.childImageSharp.fluid} alt="Vinyl record" />
        </div>
      )}
    />
  )
}