import React from "react"
import Img from "gatsby-image"
import * as imageTextStyles from "./image_text.module.css"

export default function ImageText(props) {
  return <div className='imagetext'>
        <div className={imageTextStyles.imagecontainer}>
          <Img fluid={props.image} />
          <span className={imageTextStyles.caption}>{props.caption}</span>
        </div>
        <div className={imageTextStyles.textcontainer} dangerouslySetInnerHTML={{ __html: props.html }} ></div>      
      </div>
}



