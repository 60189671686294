import React from "react"
import Layout from "../components/layout"
import Content from "../components/content"
import Article from "../components/article"
import FullSizeImage from "../components/full_size_image"
import ImageText from "../components/image_text"
import LargeImage from "../components/large_image"
import TextBlock from "../components/text_block"
import Vinyl from "../components/vinyl"
import { graphql } from 'gatsby'

export const query = graphql`
query {
  imageEmanuel: file(relativePath: { eq: "emanuel.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  imageRoger: file(relativePath: { eq: "roger.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  imageTobias: file(relativePath: { eq: "tobias.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },  
  imageVinyl: file(relativePath: { eq: "vinyl_yellow.png" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  imageProbe1: file(relativePath: { eq: "probe_1.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }, 
  imageProbe2: file(relativePath: { eq: "probe_2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },   
  imageAuftritt1: file(relativePath: { eq: "auftritt_1.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },  
  imageAuftritt2: file(relativePath: { eq: "auftritt_2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  },
}
`;

export default function Home({ data }) {
  return (
    <Layout>
      <FullSizeImage></FullSizeImage>
      <Content>
        <Article id="home">
          <h1>RÖFES Plattesammlig</h1>
          <h2>Ein Schlagermusik-Theater</h2>
          <p>Röfe führt ein mittelmässiges Leben, arbeitslos, verheiratet, aber nicht verliebt. Alles funktioniert irgendwie. Er
            lebt sein Leben, manchmal glücklich, doch oft wehmütig. Er hätte sich vieles anders vorgestellt. Zufriedenheit
            findet er in der Leidenschaft für seine Schlager-Plattensammlung. Dort kann er sich ausleben und den verpassten
            Chancen nachtrauern.</p>
          <Vinyl pos='left'></Vinyl>
        </Article>


        <Article id="das-erwartet-di">
          <h1>Das erwartet di</h1>
          <p>
            Geniesse Live-Musik mit Geschichten aus Röfes Leben. Zwar drehen die Platten, doch spielen wir für dich die
            Deutschen Schlager selbst &ndash; neu interpretiert. Erlebe akustisch-musikalische Unterhaltung, verbunden durch
            Röfes Erzählungen.
          </p>
          <LargeImage
            image={data.imageAuftritt1.childImageSharp.fluid}
            caption="Auftritt Säriswil"
          />
          <LargeImage
            image={data.imageAuftritt2.childImageSharp.fluid}
            caption="Rote Lippen soll man küssen"
          />
          <LargeImage
            image={data.imageProbe1.childImageSharp.fluid}
            caption="Vorprobe Rossstall Köniz"
          />
          <LargeImage
            image={data.imageProbe2.childImageSharp.fluid}
            caption="Letzte Vorbereitungen"
          />
        </Article>


        <Article id="dr-roefe">
          <h1>Das isch dr Röfe</h1>
          <p>56-jährig, verheiratet, wohnhaft in einem Mehrfamilienhaus in Ostermundigen. Ehemaliger Sachbearbeiter in einer Logistikfirma. Liebenswürdig und langweilig. Durchschnitt. Leidenschaftlicher Plattensammler mit Herz für Deutschen Schlager.</p>
          <ImageText
            image={data.imageEmanuel.childImageSharp.fluid}
            caption="Dr Röfe &ndash; Emanuel Gfeller"
            html='Seit 2015 ist Emanuel Gfeller als Schauspieler und Sänger tätig. In der Opernproduktion «Der Klarinettenmacher» an der Musikschule Region Burgdorf feierte er sein Bühnendebüt. 
          Seit mehreren Jahren nimmt er Gesangstunden beim Bariton Roger Bucher und wirkte bei der A-cappella-Gruppe «Stracciappella» bei rund 40 Auftritten mit.<br/><br/>
          Es folgten mehrere Schauspielrollen unter der Regie von Ulrich Simon Eggimann an der Emmentaler Liebhaberbühne. 
          An der Bühne Burgäschi übernahm er als Sänger und Schauspieler in verschiedenen Produktionen unter der Regie von Melanie Gehrig Walthert Solistenrollen.
          Daneben spielte er bei der Solothurner Liebhabertheater-Gesellschaft im Stadttheater Solothurn.<br/> <br/>
          Auch als Sprecher und Filmschauspieler ist Emanuel Gfeller tätig. Beim Oberaargauer Blasmusikcamp war er 2019 im Stück «Of Sailors and Whales» für die Sprechrolle engagiert. Für einen Werbeclip von Groove Session stand er 2019 vor der Kamera.'
          />
          <Vinyl pos='center'></Vinyl>
        </Article>

        <Article id="musig">
          <h1>Das isch d Musig</h1>
          <p>Tenor Tobias König und Bariton Roger Bucher singen Deutschen Schlager. Begleitet von ihrer Band bringen sie Hits von Peter Alexander, Roy Black, Rudi Carrell, Udo Jürgens und vielen mehr neu arrangiert auf die Bühne.</p>
          <ImageText
            image={data.imageTobias.childImageSharp.fluid}
            caption="Tobias König, Tenor"
            html='2004 wurde Tobias König an der Hochschule für Musik in Bern aufgenommen, an welcher er anfänglich von Marianne Kohler unterrichtet wurde. Später nahm er Gesangstunden bei Hans Peter Blochwitz. Tobias König sang in diversen geistlichen Werken und gab auch auf diversen Operettenbühnen ein Gastspiel.<br/><br/>
          Er sang unter anderem mit dem Capriccio Basel, für den Oratorienchor Bern, für den Arsis-Chor, den Konzertchor Burgdorf und das Ensemble Orlando. Neben dem Konzertfach war er auch als Liedsänger immer wieder tätig.<br/><br/>
          Tobias König ist ein viel gefragter Ensemblesänger. Diverse CD-Aufnahmen mit den erwähnten Gruppen zeugen von seiner Qualität als Sänger. Während Jahren tourte er mit der bekannten Jodlerin Barbara Klossner (alias Miss Helvetia) durch die Schweiz und Europa. Gegen 500 Auftritte auf diversen Kleinkunstbühnen und Festivals absolvierte er in dieser Formation. Tobias König ist Mitglied des Trios Alp Opus, dirigiert eine Alphorngruppe, schreibt Kinderlieder für Schulen und war Leadsänger der aufgelösten Mundartrockband &laquo;sonus fluctus&raquo;.'
          />
          <ImageText
            image={data.imageRoger.childImageSharp.fluid}
            caption="Roger Bucher, Bariton"
            html="Roger Bucher schloss sein Gesangsstudium bei Frau Professor Elisabeth Glauser an der Hochschule der Künste Bern (HKB) mit Lehr-, Konzert- und Operndiplom ab. Momentan erhält er weitere wichtige Impulse von Robin Adams, Hans Peter Blochwitz und Ulrich Simon Eggimann.<br/><br/>

          Zu seinem Bühnenrepertoire zählt unter anderem die Rolle des Plumkett in Friedrich von Flotows  «Martha», Guglielmo in Mozarts Oper «Così fan tutte» oder Florestan in der Barokoper  «Amadis» von Lully. In der Operette «Die lustige Witwe» war er als Danilo Danilowitsch zu hören. Es folgten die Rollen als Zsupan in «Der Zigeunerbaron», Dr. Falke in der Operette  «Die Fledermaus», der Baron Weps in  «Der Vogelhändler», Paul Normann in Burkhardts «Bezauberndes Fräulein», Karl Hell in «Grüezi» von Robert Stolz und viele mehr. 
          Als Konzertsänger hat er Engagements im In- und Ausland in den grossen Werken von Mendelssohn (Elias), Brahms (Deutsches Requiem), Rossini (Petite Messe solennelle) oder Dvorak (Te Deum).<br/><br/>
          
          Sehr wichtig für ihn ist auch der Liedgesang mit Werken von Schubert (Winterreise, Schwanengesang), Brahms (Schöne Magelone), den Balladen und Liedern von Carl Loewe oder Schumanns Dichterliebe."
          />

          <TextBlock
            title="Hanspeter Janzi, Posaune"
          />
          <TextBlock
            title="Gyorgi Spasov, Akkordeon"
          />
          <TextBlock
            title="Bernhard Bolliger, Gitarre"
          />
          <TextBlock
            title="Lorenz Bendel, Schlagzeug"
          />
          <TextBlock
            title="Tobias König, Saxophon"
          />
          <Vinyl pos='center'></Vinyl>
        </Article>


        <Article id="so-toents">
          <h1>Und so tönts</h1>
          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/ll3pB9hcDTw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <Vinyl pos='right'></Vinyl>
        </Article>


        <Article id="kontakt">
          <h1>Das isch di Kontakt</h1>
          <p>Emanuel Gfeller<br />
            <a href="tel://0041799456283">079 945 62 83</a><br />
            <a href="mailto:kontakt@roefes.ch">kontakt@roefes.ch</a><br />
            <a href="https://www.roefes.ch">www.roefes.ch</a></p>
          <Vinyl pos='left'></Vinyl>
        </Article>

        <Article id="press">
          <h1>Das seit d Press</h1>
          <p>
            <a target="_blank" rel="noreferrer" href="pdf/20230318_dregion.pdf">"Röfes Plattesammlig weckt Erinnerungen" &ndash; dRegion, 28. März 2023</a><br />
            <br />
            <a target="_blank" rel="noreferrer" href="pdf/roefes_bund_tagestipp.pdf">"Deutscher Schlager, neu interpretiert" &ndash; Tagestipp im Bund 26. November 2022</a><br />
            <br />
            <a target="_blank" rel="noreferrer" href="pdf/20221027_simmentalzeitung.pdf">"...verbrachte den unterhaltsamensten Abend seit Langem" &ndash; Simmental Zeitung, 27. Oktober 2022</a><br />
            <br />
            <a target="_blank" rel="noreferrer" href="pdf/zweisimmen.pdf">Röfes Platten boten beste Unterhaltung &ndash; Simmental Zeitung, 11. November 2021</a><br />
            <br />
            <a target="_blank" rel="noreferrer" href="https://www.bernerzeitung.ch/klasse-klassiker-349109079662">Klasse Klassiker &ndash; Berner Zeitung, 17. August 2021</a><br />
            <br />
            <a target="_blank" rel="noreferrer" href="https://www.jungfrauzeitung.ch/artikel/192830/">Standing Ovations bei der Premiere &ndash; Jungfrau Zeitung, 23. August 2021</a><br />
          </p>
          <Vinyl pos='right'></Vinyl>
        </Article>

        <Article id="uftritte">
          <h1>Die nächschte Uftritte</h1>

          <div>
            <p>ABGESAGT! Freitag, 16. Februar 2024, 19.30 Uhr &ndash; KulturKapelle9 Spiez<br />
              Der Auftritt ist abgesagt und wird verschoben. Weitere Infos folgen.</p>

            <p>Samstag, 24. August 2024 als Teil des "Gondel Night Dinner" &ndash; Rinderberg, Zweisimmen<br />
              (Röfes Plattesammlig spielt nur am 24. August, nicht an den andern Daten)<br />
              Weitere Infos unter <a target="_blank" rel="noreferrer" href="https://www.gstaad.ch/events.html#/veranstaltungen/GRI/1d83c6ef-3ca3-49fe-9e06-f001186c924b/gondel-night-dinner">https://www.gstaad.ch/</a></p>
            <br /><br />
          </div>

          <div>
            <p>Scho verbi</p>
            <p>
              21. Oktober 2023, 20.15 Uhr &ndash; Kreuzkeller Herzogenbuchsee<br />
              12. Oktober 2023, ab 19 Uhr &ndash; Bellevue, Lüsslingen<br />
              19. August 2023, 20 Uhr &ndash; Reusspark Niederwil<br />
              22. April 2023, 20 Uhr &ndash; Käch-Schüür Oberdorf<br />
              24. März 2023, 20 Uhr &ndash; Stadthauskeller Burgdorf<br />
              26. November 2022 &ndash; Rossstall Köniz<br />
              29. Oktober 2022 &ndash; Rössli Rothrist<br />
              6. Oktober 2022 &ndash; Rössli Säriswil<br />
              16. September 2022 &ndash; Bären Biglen<br />
              9. September 2022 &ndash; Theater am Käfigturm<br />
              12. November 2021 &ndash; Hagerhüsli Bätterkinden <br />
              5. November 2021 &ndash; Musikhaus Zweisimmen <br />
              20. August 2021 &ndash; Premiere im Rössli Uetendorf<br />
            </p>
            <br />
          </div>

        </Article>

      </Content>
    </Layout>
  );
}