import React from "react"
import * as footerStyles from "./footer.module.css"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        imageFacebook: file(relativePath: { eq: "f_logo_RGB-White_72.png" }) {
          childImageSharp {
            fluid(maxWidth: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }, 
        imageInstagram: file(relativePath: { eq: "Instagram_Glyph_White.png" }) {
          childImageSharp {
            fluid(maxWidth: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        },         
      }
      `}
      render={data => (
          <footer className={footerStyles.footer}>
            <div className={footerStyles.contact}>
              <div>
                <p>Emanuel Gfeller<br/>
                  <a href="tel://0041799456283">079 945 62 83</a>
                </p>
              </div>
              <div>
                <p>
                  <a href="mailto:kontakt@roefes.ch">kontakt@roefes.ch</a><br/>
                  <a href="https://www.roefes.ch">www.roefes.ch</a>
              </p>
              </div>
            </div>

            <div className={footerStyles.social}>
              <OutboundLink title="Facebook" href="https://www.facebook.com/R%C3%B6fes-Plattesammlig-113113491027233" target="_blank" rel="noreferrer">
                <Img fluid={data.imageFacebook.childImageSharp.fluid} alt="Facebook Logo" />
              </OutboundLink>
              <OutboundLink title="Instagram" href="https://www.instagram.com/roefesplattesammlig/" target="_blank" rel="noreferrer">
                <Img fluid={data.imageInstagram.childImageSharp.fluid} alt="Instagram Logo" />
              </OutboundLink>
            </div>
        </footer>
      )}
    />
  )
}